<script lang="ts" setup>
import type { FetchError } from 'ofetch'
import VInput from '~/components/atoms/VInput/VInput.vue'

const { $apiFetch } = useNuxtApp()

const { t } = useI18n()
const route = useRoute()
const email = ref('')
const magicLinkSent = ref(false)

interface VLoginProps {
    redirect?: string
}

const { redirect } = defineProps<VLoginProps>()
const emits = defineEmits(['login'])

const pending = ref(false)
const errorMessage = ref<string | null>(null)
const onLogin = async (event: Event) => {
    event.preventDefault()
    errorMessage.value = null
    pending.value = true

    try {
        await $apiFetch('/login', {
            method: 'POST',
            query: {
                redirect: redirect || route.path || null,
            },
            body: JSON.stringify({
                email: email.value,
            }),
        })
        magicLinkSent.value = true
        emits('login')
        useTrackEvent('Login')
        pending.value = false
    }
    catch (error: unknown) {
        pending.value = false
        if ((error as FetchError).status === 429) {
            // User not found, redirect to sign in page
            errorMessage.value = t('too_many_connection_attempt')
        }
        else if ((error as FetchError).status === 400) {
            // User not found, redirect to sign in page
            errorMessage.value = (error as FetchError).data?.['hydra:description']
        }
        else {
            throw error
        }
    }
}
</script>

<template>
    <div v-if="!magicLinkSent">
        <form
            class="narrow-content"
            :class="[!!errorMessage && $style['form-has-errors'], $style.form]"
            @submit="onLogin"
        >
            <VInput
                id="magic_link_email"
                v-model="email"
                :label="t('email_address')"
                :error="errorMessage"
                type="email"
                autocomplete="email"
                required
                :disabled="pending"
            />
            <div>
                <LazyVSnackbar
                    v-if="errorMessage"
                    type="error"
                    :title="errorMessage"
                />
                <VButton
                    size="xl"
                    filled
                    theme="yellow"
                    :loading="pending"
                    type="submit"
                    :class="$style.submit"
                    :label="t('login')"
                />
            </div>
        </form>
    </div>
    <VNotificationContent
        v-else
        icon-name="mail"
        :title="t('check_your_mailbox')"
        :content="t('connection_link_has_been_sent', { email })"
    />
</template>

<style lang="scss" module>
.form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2-xs);
}

.submit {
    display: flex;
    margin: var(--v-form-submit-button-margin, #{rem(32) auto});
}
</style>
