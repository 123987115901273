<script setup lang="ts">
import { VLogin, VSignIn } from '#components'

type VLoginType = 'login' | 'signIn'

const route = useRoute()
const redirect = computed(() => route.query.redirect as string | undefined)

const loginRequested = ref(false)
const onLogin = () => loginRequested.value = true

const signInRequested = ref(false)
const onSignedIn = () => signInRequested.value = true

const displayedType = ref<VLoginType>('signIn')

const displayedComponent = computed(() => {
    if (displayedType.value === 'signIn') return VSignIn
    else return VLogin
})
const { t } = useI18n()

const headData = computed(() => {
    if (displayedType.value === 'signIn') {
        return {
            message: t('signin.has_account_message'),
            buttonLabel: t('signin.has_account_label'),
        }
    }

    return {
        message: t('signin.create_account_message'),
        buttonLabel: t('signin.create_account_label'),
    }
})

function toggleLoginType() {
    displayedType.value = displayedType.value === 'signIn' ? 'login' : 'signIn'
}
</script>

<template>
    <div :class="$style.head">
        <span class="text-body-m">{{ headData.message }}</span>
        <VButton
            outlined
            :label="headData.buttonLabel"
            size="s"
            icon-name="arrow-small-right"
            @click="toggleLoginType"
        />
    </div>

    <KeepAlive>
        <component
            :is="displayedComponent"
            :redirect="displayedType === 'login' ? redirect : undefined"
            @login="onLogin"
            @sign-in="onSignedIn"
        />
    </KeepAlive>
</template>

<style lang="scss" module>
.root {
    position: relative;
}

.head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--spacing-xl);
    gap: rem(16) rem(12);
}
</style>
