<script lang="ts" setup>
import type { FetchError } from 'ofetch'
import type { Address, ConstraintViolationList } from '~/types/api'
import { countries } from '~/utils/countries'
import VInput from '~/components/atoms/VInput/VInput.vue'

type PostAddress = Omit<Address, '@id' | '@type'>
const { t } = useI18n()
const { $apiFetch } = useNuxtApp()

const route = useRoute()
const pending = ref(false)
const errorMessage = ref<string | null>(null)
const email = ref('')
const emailError = ref<string | null>(null)
const title = ref('mr')
const titleError = ref<string | null>(null)
const firstName = ref('')
const firstNameError = ref<string | null>(null)
const lastName = ref('')
const lastNameError = ref<string | null>(null)
const telephone = ref('')
const telephoneError = ref<string | null>(null)
const address = ref<PostAddress>({
    streetAddress: '',
    postalCode: '',
    addressLocality: '',
    addressCountry: 'FR',
})
const addressError = ref<string | null>(null)
const streetAddressError = ref<string | null>(null)
const postalCodeError = ref<string | null>(null)
const addressLocalityError = ref<string | null>(null)
const addressCountryError = ref<string | null>(null)
const magicLinkSent = ref(false)

const emits = defineEmits(['signIn'])
const availableCountries = countries
const { loadRecaptcha } = useRecaptchaLoaded()

const onSignIn = async (event: Event) => {
    event.preventDefault()
    pending.value = true
    resetErrors()

    try {
        const token = await loadRecaptcha('sign_in')
        await $apiFetch('/users', {
            method: 'POST',
            body: JSON.stringify({
                email: email.value,
                title: title.value,
                firstName: firstName.value,
                lastName: lastName.value,
                telephone: telephone.value,
                address: address.value,
            }),
            query: {
                'redirect': route.path || null,
                'g-recaptcha-response': token,
            },
        })

        magicLinkSent.value = true
        emits('signIn')
        useTrackEvent('Register')
        pending.value = false
    }
    catch (error: unknown) {
        pending.value = false
        if (error === null) {
            errorMessage.value = t('unknown_error')
        }
        else if ((error as FetchError).status === 429) {
            // User not found, redirect to sign in page
            errorMessage.value = t('too_many_signin_attempt')
        }
        else if ((error as FetchError).status === 422) {
            const violations = (error as FetchError).data as ConstraintViolationList
            if (violations['@type'] === 'ConstraintViolationList') {
                for (const violation of violations.violations) {
                    switch (violation.propertyPath) {
                        case 'email':
                        case 'canonicalEmail':
                            emailError.value = violation.message
                            break
                        case 'title':
                            titleError.value = violation.message
                            break
                        case 'firstName':
                            firstNameError.value = violation.message
                            break
                        case 'lastName':
                            lastNameError.value = violation.message
                            break
                        case 'telephone':
                            telephoneError.value = violation.message
                            break
                        case 'address.streetAddress':
                            streetAddressError.value = violation.message
                            break
                        case 'address.postalCode':
                            postalCodeError.value = violation.message
                            break
                        case 'address.addressLocality':
                            addressLocalityError.value = violation.message
                            break
                        case 'address':
                            addressError.value = violation.message
                            break
                        default:
                            errorMessage.value = violation.message
                            break
                    }
                }
            }
        }
    }
}
const resetErrors = () => {
    errorMessage.value = null
    emailError.value = null
    titleError.value = null
    firstNameError.value = null
    lastNameError.value = null
    telephoneError.value = null
    addressError.value = null
    streetAddressError.value = null
    postalCodeError.value = null
    addressLocalityError.value = null
    addressCountryError.value = null
}
const hasErrors = computed(() => {
    return (
        errorMessage.value
        || emailError.value
        || titleError.value
        || firstNameError.value
        || lastNameError.value
        || telephoneError.value
        || addressError.value
        || streetAddressError.value
        || postalCodeError.value
        || addressLocalityError.value
        || addressCountryError.value
    )
})
</script>

<template>
    <template v-if="!magicLinkSent">
        <form
            class="narrow-content"
            :class="[hasErrors && $style['form-has-errors'], $style.form]"
            @submit="onSignIn"
        >
            <div
                :class="$style.gender"
                class="horizontal-input-group"
            >
                <VInput
                    id="title_mr"
                    horizontal
                    :label="t('mister')"
                    :error="titleError"
                    required
                    :disabled="pending"
                >
                    <input
                        id="title_mr"
                        v-model="title"
                        :disabled="pending"
                        required
                        value="mr"
                        autocomplete="honorific-prefix"
                        type="radio"
                    >
                </VInput>
                <VInput
                    id="title_mrs"
                    horizontal
                    :label="t('madam')"
                    :error="titleError"
                    required
                    :disabled="pending"
                >
                    <input
                        id="title_mrs"
                        v-model="title"
                        :disabled="pending"
                        required
                        value="mrs"
                        autocomplete="honorific-prefix"
                        type="radio"
                    >
                </VInput>
            </div>

            <div class="horizontal-input-group">
                <VInput
                    id="signin_firstname"
                    v-model="firstName"
                    :label="t('firstName')"
                    :error="firstNameError"
                    type="text"
                    autocomplete="given-name"
                    required
                    :disabled="pending"
                />
                <VInput
                    id="signin_lastname"
                    v-model="lastName"
                    :label="t('lastName')"
                    :error="lastNameError"
                    type="text"
                    autocomplete="family-name"
                    required
                    :disabled="pending"
                />
            </div>

            <VInput
                id="signin_email"
                v-model="email"
                :label="t('email_address')"
                :error="emailError"
                type="email"
                autocomplete="email"
                required
                :disabled="pending"
            />

            <VInput
                id="signin_telephone"
                v-model="telephone"
                :label="t('telephone')"
                :error="telephoneError"
                type="tel"
                autocomplete="tel"
                required
                :disabled="pending"
            />

            <div
                class="form-group"
                :class="[addressError && $style['has-errors'], $style.form]"
            >
                <VInput
                    id="signin_address_street_address"
                    v-model="address.streetAddress"
                    :label="t('address.streetAddress')"
                    :error="streetAddressError"
                    type="text"
                    autocomplete="address-line1"
                    required
                    :disabled="pending"
                />

                <div class="horizontal-input-group">
                    <VInput
                        id="signin_address_postal_code"
                        v-model="address.postalCode"
                        :label="t('address.postalCode')"
                        :error="postalCodeError"
                        type="text"
                        autocomplete="postal-code"
                        required
                        :disabled="pending"
                    />
                    <VInput
                        id="signin_address_address_locality"
                        v-model="address.addressLocality"
                        :label="t('address.addressLocality')"
                        :error="addressLocalityError"
                        type="text"
                        autocomplete="city"
                        required
                        :disabled="pending"
                    />
                    <VInput
                        id="signin_address_address_country"
                        :label="t('address.addressCountry')"
                        :error="addressCountryError"
                        required
                        :disabled="pending"
                    >
                        <select
                            id="signin_address_address_country"
                            v-model="address.addressCountry"
                            :disabled="pending"
                            required
                            autocomplete="country"
                        >
                            <option value="FR">
                                France
                            </option>
                            <optgroup :label="t('address.addressCountry.all')">
                                <option
                                    v-for="(countryName, countryCode) in availableCountries"
                                    :key="countryCode"
                                    :value="countryCode"
                                >
                                    {{ countryName }}
                                </option>
                            </optgroup>
                        </select>
                    </VInput>
                </div>

                <VInput
                    id="form_gdpr_consent"
                    horizontal
                    :label="t('contact_form.gdpr_consent')"
                    required
                    :disabled="pending"
                    type="checkbox"
                    separator
                />
            </div>
            <div>
                <LazyVSnackbar
                    v-if="hasErrors"
                    type="error"
                    :title="$t('form_was_not_sent')"
                    :message="$t('form_contains_errors')"
                />
                <LazyVSnackbar
                    v-if="errorMessage"
                    type="error"
                    :title="errorMessage"
                />
                <VButton
                    size="xl"
                    filled
                    theme="yellow"
                    :loading="pending"
                    type="submit"
                    :class="$style.submit"
                    :label="t('signin.button.label')"
                />
            </div>
        </form>
    </template>
    <VNotificationContent
        v-else
        icon-name="mail"
        :title="t('check_your_mailbox')"
        :content="t('signin.confirmation', { email: email })"
    />
</template>

<style lang="scss" module>
.form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2-xs);
}

.gender {
    margin-block: rem(14);
}

.submit {
    display: flex;
    margin: var(--v-form-submit-button-margin, #{rem(32) auto});
}
</style>
